/* Contact Container */
.contactContainer {
  height: fit-content;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(29, 29, 29, 0.8);
  padding: 2rem 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-image: url(../../assets/contact_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
/* Text Section */
.textSection {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
/* Title Section */
.title {
  margin-right: auto;
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.title h2 {
  /* font-family: 'Source Code Pro', monospace; */
  font-weight: 500;
  font-size: 24px;
}
.title span {
  color: #cc4343;
}
/* UnderLine Line */
.line {
  height: 1px;
  background-color: rgba(239, 239, 239, 0.5);
  width: 200px;
  margin-right: auto;
  margin-bottom: 2rem;
}
/* Form */
.contactContainer form {
  display: flex;
  flex-direction: column;
}
/* Form Label */
.contactContainer form label {
  font-family: 'Karla', sans-serif;
  font-weight: 300;
  color: #c4c4c4;
  font-size: 18px;
}
/* Form Input */
.contactContainer form input,
.contactContainer form textarea {
  background-color: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 0.5rem;
  color: white;
  margin-bottom: 1rem;
  font-family: 'Karla', sans-serif;
  font-size: 18px;
}
.contactContainer form textarea {
  max-width: 100%;
}
/* Submit Button */
.submitBtn {
  display: flex;
  font-family: 'Pridi', serif;
  font-weight: 300;
  color: #c4c4c4;
  font-size: 18px;
  background-color: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 0.5rem 1rem;
  width: fit-content;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: auto;
}
.submitBtn:hover {
  background-color: rgba(196, 196, 196, 0.5);
}
.submitBtn:active {
  background-color: rgba(196, 196, 196, 0.3);
}
/* Additional Area */
.additionalLinks {
  margin-top: 2rem;
  font-family: 'Playfair Display', serif;
  color: #c4c4c4;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.additionalIcons {
  margin-top: 2rem;
  font-size: 42px;
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.additionalIcons a {
  color: #c4c4c4;
  transition: all 0.2s ease;
}
.additionalIcons .linkedinIcon,
.additionalIcons .githubIcon,
.additionalIcons .spotifyIcon,
.additionalIcons .youtubeIcon,
.additionalIcons .applemusicIcon {
  padding: 0.1rem;
}
.additionalIcons .linkedinIcon:hover {
  color: rgba(44, 99, 187, 100%);
  background-color: white;
  border-radius: 5px;
}
.additionalIcons .githubIcon:hover {
  color: rgba(36, 40, 45, 100%);
  background-color: white;
  border-radius: 5px;
}
.additionalIcons .spotifyIcon:hover {
  color: #65d46e;
  background-color: white;
  border-radius: 5px;
}
.additionalIcons .youtubeIcon:hover {
  color: rgba(233, 51, 34, 100%);
  background-color: white;
  border-radius: 5px;
}
.additionalIcons .applemusicIcon:hover {
  color: rgba(230, 76, 86, 100%);
  background-color: white;
  border-radius: 5px;
}
