/* Card Container */
.cardContainer {
  height: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  /* margin: 0 auto; */
}
/* Title Section */
.title {
  display: flex;
  align-content: baseline;
  justify-content: space-between;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 1rem;
}
/* Title Icon Div */
.titleIcons {
  display: flex;
}
.titleIcons a {
  margin-left: 1rem;
}
/* Site Link */
.title .siteLink {
  font-weight: 200;
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease;
}
.title .siteLink:hover {
  color: #9f8e88;
}
/* Github Link */
.title .githubLink {
  color: inherit;
  text-decoration: none;
  height: fit-content;
  display: flex;
}
.icon {
  font-size: 30px;
  align-self: baseline;
  transition: all 0.2s ease;
}
.icon:hover {
  color: #9f8e88;
}
/* Content */
.content p {
  font-family: 'Karla', sans-serif;
  margin-bottom: 2rem;
  text-align: center;
}
/* Carousel */
.carousel {
  margin: 0 auto;
  margin-bottom: 3rem;
  /* width: 380px; */
  width: calc(100vw - 3rem);
  max-width: 800px;
  transition: all 0.2s ease;
}
.carousel div p {
  margin-top: 1rem;
  margin-bottom: 3rem;
}
/* Bottom Border Line */
.line {
  height: 1px;
  width: 95vw;
  max-width: 800px;
  background-color: rgba(239, 239, 239, 0.5);
  opacity: 0.2;
  margin: 0 auto;
  margin-bottom: 2rem;
}
@media (min-width: 900px) {
  .carousel {
    width: 100%;
  }
  .cardContainer {
    margin: 0 auto;
  }
}
@media (max-width: 300px) {
  .cardContainer {
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .carousel {
    width: calc(100vw - 2rem);
  }
}
