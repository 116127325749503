/* About Container */
.aboutContainer {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%); */
  /* background-color: #424e81; */
  background-color: rgb(66, 77, 66);
  padding: 2rem 1rem;
  /* margin-top: -10rem; */
  /* padding-top: 8rem; */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
/* Text Section */
.textSection {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.title {
  margin-right: auto;
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.title h2 {
  /* font-family: 'Karla', sans-serif; */
  font-weight: 700;
  font-size: 24px;
}
.title span {
  color: #c64141;
}

/* Image */
.textSection a {
  display: block;
  height: 300px;
  width: 300px;
  background-image: url(../../assets/Me.jpg);
  background-size: cover;
  border-radius: 50%;
  /* box-shadow: inset 3px 3px 8px rgba(0, 0, 0, 0.5); */
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.textSection a:hover {
  filter: sepia(0.2);
}

/* Paragraph */
.textSection p {
  font-family: 'Karla', sans-serif;
  text-align: justify;
  font-size: 1.3rem;
}
/* UnderLine Line */
.line {
  height: 1px;
  background-color: rgba(239, 239, 239, 0.5);
  width: 130px;
  margin-right: auto;
  margin-bottom: 2rem;
}

/* Coding Icons */
.languages {
  margin-top: 2rem;
}
.languages p {
  font-size: 1rem;
}
.codeIconsText {
  text-align: center;
  font-size: 20px;
}
.languages div {
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 50px;
  margin: 0.5rem;
}
