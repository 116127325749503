/* Projects Container */
.projectsContainer {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #485589; */
  /* background-color: rgb(66, 75, 87); */
  background: linear-gradient(rgb(66, 75, 87), #1d355b);
  padding: 2rem 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.title {
  margin-left: auto;
  text-align: right;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.title h2 {
  font-weight: 700;
  font-size: 24px;
}
.title span {
  color: #cc4343;
}
/* UnderLine Line */
.line {
  height: 1px;
  background-color: rgba(239, 239, 239, 0.5);
  width: 130px;
  margin-left: auto;
  margin-bottom: 2rem;
}

.revProjects {
  display: flex;
  flex-direction: column-reverse;
}