/* Hero Container */
.heroContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url(../../assets/lava-lake.png); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  padding: 1rem;
}
/* .heroContainer img {
  object-fit: cover;
  position: absolute;
  height: 100vh;
  width: 100%;
  opacity: 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.heroImg {
  width: 100%;
  background-image: url('../../assets/lava-lake.png');
  height: 100vh;
  position: absolute;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
}
/* 'Landon Spell' */
.heroContainer h1 {
  font-size: 42px;
  font-weight: 400;
  margin: 1rem auto;
  /* margin-top: -10rem; */
  opacity: 0;
  /* animation: fade 1s ease forwards; */
  cursor: default;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  transform: translateY(8px);
}
/* Period */
.heroContainer h1 span {
  color: var(--accent-red);
}
/* UL Element */
.heroContainer ul {
  text-align: center;
  list-style: none;
  font-size: 24px;
}
.heroContainer li {
  margin: 1rem auto;
  font-family: 'Karla', sans-serif;
  font-weight: 300;
  font-size: 28px;
  opacity: 0;
  transform: translateY(8px);
  /* animation: fade 1s ease forwards;
  animation-delay: 0.75s; */
}
/* Anchor Tags */
.heroContainer ul li a {
  color: rgba(239, 239, 239, 0.5);
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
}
.heroContainer ul li a:hover {
  color: rgba(239, 239, 239, 1);
  /* color: var(--accent-green); */
  text-decoration: none;
}

/* Arrow Icon */
.arrowIcon {
  z-index: 2;
  font-size: 3rem;
  margin-top: auto;
  margin-bottom: 1rem;
  opacity: 0;
  /* color: #aa5d59; */
  animation: arrowColor 3s ease-in-out infinite alternate;
}
.textArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  height: 100vh;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes arrowColor {
  100% {
    color: var(--accent-red);
    transform: scale(1.2);
  }
}
