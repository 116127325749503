@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800;900&family=Pridi:wght@300&family=Source+Code+Pro:wght@200;300;400;500;600;700;800;900&display=swap');
:root {
  --accent-red: #cc4343;
  --accent-green: rgb(68, 109, 51);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #efefef;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* font-family: 'Karla', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Source Code Pro', monospace; */
/* font-family: 'Pridi', serif; */
